import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Slider,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Collapse,
  TextField,
  Pagination,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FilterList, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../../state";
import Item from "../../components/Item";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { BASE_URL } from "../../api";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

// Functie om minimalAge key naar leesbaar label te converteren
const formatMinimalAgeLabel = (key) => {
  // Voeg spaties toe voor elke hoofdletter die volgt op een kleine letter of een cijfer
  let spacedString = key.replace(/(?<=[a-z0-9])([A-Z])/g, " $1");

  // Zet de eerste letter naar hoofdletter en de rest naar kleine letters
  spacedString =
    spacedString.charAt(0).toUpperCase() + spacedString.slice(1).toLowerCase();

  return spacedString;
};

const ShoppingList = ({ isSearchOpen }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);
  const breakPoint = useMediaQuery("(min-width:600px)");
  const isMobile = !breakPoint;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Gebruik useCallback om formatCategoryName te memoizeren
  const formatCategoryName = useCallback((category) => {
    let spacedString = category.replace(/(?<=[a-z])([A-Z])/g, " $1");
    spacedString = spacedString.replace(
      /(?<=[a-zA-Z])(\d+)|(\d+)(?=[a-zA-Z])/g,
      " $1$2 "
    );
    const splitWords = spacedString.trim().split(/\s+/);
    return splitWords
      .map((word) => {
        if (!isNaN(word)) return word;
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }, []);

  // Extractie van URL-parameters
  const params = new URLSearchParams(location.search);
  const categoryFromURL = params.getAll("category");
  const suppliersFromURL = params.getAll("suppliers");
  const kaliberFromURL = params.getAll("kaliber");
  const minimalAgeFromURL = params.getAll("minimalAge"); // Nieuwe minimalAge parameter
  const priceRangeFromURL = params
    .get("priceRange")
    ?.split(",")
    .map(Number) || [0, 500];
  const bestsellersFromURL = params.get("bestsellers") === "true";
  const inStockFromURL = params.get("inStock") === "true";

  // Initialiseer filterstaat met minimalAge
  const [filters, setFilters] = useState({
    searchTerm: "",
    categories:
      categoryFromURL.length > 0 ? categoryFromURL.map(formatCategoryName) : [],
    suppliers: suppliersFromURL.length > 0 ? suppliersFromURL : [],
    kaliber: kaliberFromURL.length > 0 ? kaliberFromURL : [],
    minimalAge: minimalAgeFromURL.length > 0 ? minimalAgeFromURL : [],
    priceRange: priceRangeFromURL,
    bestsellers: bestsellersFromURL,
    inStock: inStockFromURL,
  });

  // Memoized categorieën
  const categories = useMemo(
    () =>
      Array.from(
        new Set(
          items.map((item) => formatCategoryName(item.attributes.category))
        )
      ).sort((a, b) => a.localeCompare(b)),
    [items, formatCategoryName]
  );

  // Memoized leveranciers
  const suppliers = useMemo(
    () =>
      Array.from(
        new Set(items.map((item) => item.attributes.supplier).filter(Boolean))
      ).sort((a, b) => a.localeCompare(b)),
    [items]
  );

  // Memoized kaliber opties
  const kaliberOptions = useMemo(() => {
    const kaliberSet = new Set(
      items.map((item) => item.attributes.kaliber).filter(Boolean)
    );
    kaliberSet.add("Niet bekend");
    return Array.from(kaliberSet).sort((a, b) => a.localeCompare(b));
  }, [items]);

  // Automatisch gegenereerde minimalAge opties
  const minimalAgeOptions = useMemo(() => {
    const set = new Set(
      items.map((item) => item.attributes.minimalAge || "minimaal16JaarOud")
    );
    return Array.from(set).sort();
  }, [items]);

  const [loading, setLoading] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [sortOption, setSortOption] = useState(() => {
    return localStorage.getItem("sortOption") || ""; // Load from localStorage
  });

  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return Number(localStorage.getItem("itemsPerPage")) || 10; // Load from localStorage
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [openCategories, setOpenCategories] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openKaliber, setOpenKaliber] = useState(false);
  const [openMinimalAge, setOpenMinimalAge] = useState(false); // Nieuwe state voor minimalAge collapse

  // Haal items op van de API
  useEffect(() => {
    async function getItems() {
      const response = await fetch(`${BASE_URL}/api/items?populate=image`);
      const data = await response.json();
      dispatch(setItems(data.data));
      setLoading(false);
    }

    getItems();
  }, [dispatch]);
  // Custom CSS for hiding scrollbars
  const customScrollStyles = {
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // For Internet Explorer and Edge
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and other WebKit browsers
    },
  };

  // Handler voor checkbox veranderingen
  const handleCheckboxChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      setFilters((prevFilters) => {
        let updatedArray;

        if (value === "Alles") {
          if (name === "categories") {
            updatedArray =
              prevFilters[name].length === categories.length ? [] : categories;
          } else if (name === "suppliers") {
            updatedArray =
              prevFilters[name].length === suppliers.length ? [] : suppliers;
          } else if (name === "kaliber") {
            updatedArray =
              prevFilters[name].length === kaliberOptions.length
                ? []
                : kaliberOptions;
          }
          // "minimalAge" wordt niet langer behandeld voor "Alles"
          else {
            updatedArray = [];
          }
        } else {
          updatedArray = prevFilters[name].includes(value)
            ? prevFilters[name].filter((item) => item !== value)
            : [...prevFilters[name], value];
        }

        return { ...prevFilters, [name]: updatedArray };
      });

      setCurrentPage(1);
    },
    [categories, suppliers, kaliberOptions, minimalAgeOptions]
  );

  // Handler voor slider veranderingen
  const handleSliderChange = useCallback((event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: newValue,
    }));
    setCurrentPage(1);
  }, []);

  // Toggle inStock filter
  const toggleInStock = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      inStock: !prevFilters.inStock,
    }));
    setCurrentPage(1);
  }, []);

  // Toggle bestsellers filter
  const toggleHighlight = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      bestsellers: !prevFilters.bestsellers,
    }));
    setCurrentPage(1);
  }, []);

  // Handler voor zoekterm veranderingen
  const handleSearchTermChange = useCallback((event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchTerm: event.target.value,
    }));
    setCurrentPage(1);
  }, []);

  // Synchroniseer filters met URL parameters
  useEffect(() => {
    const newParams = new URLSearchParams();

    if (filters.categories.length > 0) {
      newParams.set("category", filters.categories.join(","));
    }
    if (filters.suppliers.length > 0) {
      newParams.set("suppliers", filters.suppliers.join(","));
    }
    if (filters.kaliber.length > 0) {
      newParams.set("kaliber", filters.kaliber.join(","));
    }
    if (filters.minimalAge.length > 0) {
      newParams.set("minimalAge", filters.minimalAge.join(","));
    }
    if (filters.priceRange) {
      newParams.set("priceRange", filters.priceRange.join(","));
    }
    if (filters.bestsellers) {
      newParams.set("bestsellers", "true");
    }
    if (filters.inStock) {
      newParams.set("inStock", "true");
    }

    navigate(
      {
        search: newParams.toString().replace(/,/g, "&"),
      },
      { replace: true }
    );
  }, [filters, navigate]);

  // Placeholder voor applyFilters functie
  const applyFilters = useCallback(() => {
    setLoading(true);
    // Voeg hier eventuele aanvullende logica toe indien nodig
    setLoading(false);
  }, []);

  // Debounced applyFilters om performance te optimaliseren
  const debouncedApplyFilters = useMemo(
    () => debounce(applyFilters, 300),
    [applyFilters]
  );

  // Effect om filters en sortering toe te passen wanneer afhankelijkheden veranderen
  useEffect(() => {
    debouncedApplyFilters();
    return () => {
      debouncedApplyFilters.cancel();
    };
  }, [filters, sortOption, debouncedApplyFilters]);

  // Toggle zichtbaarheid filters op mobiel
  const toggleFilters = useCallback(() => {
    setFiltersVisible(!filtersVisible);
  }, [filtersVisible]);

  // Handler voor sorteeroptie veranderingen
  const handleSortChange = useCallback((event) => {
    const newSortOption = event.target.value;
    setSortOption(newSortOption);
    localStorage.setItem("sortOption", newSortOption);
    setCurrentPage(1);
  }, []);

  // Handler voor items per pagina veranderingen
  const handleItemsPerPageChange = useCallback((event) => {
    const newItemsPerPage = event.target.value;
    setItemsPerPage(newItemsPerPage);
    localStorage.setItem("itemsPerPage", newItemsPerPage);
    setCurrentPage(1);
  }, []);

  // Handler voor pagina veranderingen
  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  // Toepassen van filters en sorteren
  const applyFiltersAndSort = useMemo(() => {
    const filteredAndSortedItems = items
      .filter((item) => {
        const priceToUse =
          item.attributes.priceDiscount || item.attributes.price;

        // Bepaal minimalAge van het item, standaard op "minimaal16JaarOud" als het leeg is
        const itemMinimalAge =
          item.attributes.minimalAge || "minimaal16JaarOud";

        return (
          (filters.searchTerm === "" ||
            item.attributes.name
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase())) &&
          (filters.categories.length === 0 ||
            filters.categories.includes(
              formatCategoryName(item.attributes.category)
            )) &&
          (filters.suppliers.length === 0 ||
            filters.suppliers.includes(item.attributes.supplier)) &&
          (filters.kaliber.length === 0 ||
            filters.kaliber.includes(item.attributes.kaliber) ||
            (filters.kaliber.includes("Niet bekend") &&
              !item.attributes.kaliber)) &&
          (filters.minimalAge.length === 0 ||
            filters.minimalAge.includes(itemMinimalAge)) &&
          priceToUse >= filters.priceRange[0] &&
          priceToUse <= filters.priceRange[1] &&
          (!filters.inStock || !item.attributes.outOfStock) &&
          (!filters.bestsellers || item.attributes.highlight)
        );
      })
      .sort((a, b) => {
        if (!sortOption) return 0;

        const priceA = a.attributes.priceDiscount || a.attributes.price;
        const priceB = b.attributes.priceDiscount || b.attributes.price;

        if (sortOption === "priceAsc") {
          return priceA - priceB;
        } else if (sortOption === "priceDesc") {
          return priceB - priceA;
        } else if (sortOption === "nameAsc") {
          return a.attributes.name.localeCompare(b.attributes.name);
        } else if (sortOption === "nameDesc") {
          return b.attributes.name.localeCompare(a.attributes.name);
        } else if (sortOption === "mostSold") {
          // Nieuwe sorteeroptie
          const soldA = a.attributes.totalSold || 0; // Behandel lege waarden als 0
          const soldB = b.attributes.totalSold || 0;
          return soldB - soldA; // Sorteren van hoog naar laag
        }
        return 0;
      });

    setTotalItems(filteredAndSortedItems.length);

    return filteredAndSortedItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [
    items,
    filters,
    sortOption,
    currentPage,
    itemsPerPage,
    formatCategoryName,
  ]);

  if (loading) {
    return (
      <Typography variant="h3" align="center">
        Laden...
      </Typography>
    );
  }

  return (
    <Box
      width="80%"
      margin="60px auto"
      className="shift-on-search"
      display="flex"
      flexDirection="column"
    >
      {/* Filter knop voor mobiel */}
      {isMobile && !filtersVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            left: 0,
            right: 0,
            zIndex: 9,
            backgroundColor: theme.palette.background.default,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton
            onClick={toggleFilters}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              color: theme.palette.text.primary, // Zorgt ervoor dat het icoontje zich aanpast aan het thema
            }}
          >
            <FilterList />
            <Typography
              variant="button"
              sx={{ ml: 1, color: theme.palette.text.primary }} // Zorgt ervoor dat de tekst zich aanpast aan het thema
            >
              Filter
            </Typography>
          </IconButton>
        </Box>
      )}

      {/* Sticky Header voor Desktop */}
      {!isMobile && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{
            position: "sticky",
            top: "60px", // Hoogte van de navbar
            zIndex: 10,
            backgroundColor: theme.palette.background.default,
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: "10px 0",
          }}
        >
          <Typography variant="h3" textAlign="left">
            <b>Assortiment:</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ mr: 2 }}>
              Aantal items per pagina:
            </Typography>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              sx={{ minWidth: 80, mr: 2 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Sorteren op:
            </Typography>
            <Select
              value={sortOption}
              onChange={handleSortChange}
              sx={{ minWidth: 150 }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Sorteren op
              </MenuItem>
              <MenuItem value="nameAsc">Naam (A-Z)</MenuItem>
              <MenuItem value="nameDesc">Naam (Z-A)</MenuItem>
              <MenuItem value="priceAsc">Prijs (Laag-Hoog)</MenuItem>
              <MenuItem value="priceDesc">Prijs (Hoog-Laag)</MenuItem>
              <MenuItem value="mostSold">Meest Verkocht</MenuItem>{" "}
              {/* Nieuwe optie */}
            </Select>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        width="100%"
        flexDirection={isMobile ? "column" : "row"}
      >
        {/* Filters Paneel */}
        {(filtersVisible || !isMobile) && (
          <Box
            position={isMobile ? "fixed" : "sticky"} // Gebruik sticky voor desktop
            top={isMobile ? "60px" : "60px"} // Zorg ervoor dat het bovenaan blijft onder de header
            left={isMobile ? "0" : "auto"}
            width={isMobile ? "100%" : "20%"} // Verander de breedte indien nodig
            height={isMobile ? "calc(100vh - 60px)" : "70vh"} // Zorg voor volledige hoogte
            bgcolor={isMobile ? theme.palette.background.default : "inherit"}
            zIndex={isMobile ? "999" : "1"} // Zorg ervoor dat het boven andere elementen staat
            p={2}
            display={isMobile && !filtersVisible ? "none" : "block"}
            boxShadow={isMobile ? 3 : "none"}
            sx={{
              borderRight: "1px solid",
              borderColor: theme.palette.divider,
              borderRadius: isMobile ? "10px" : "none",
              overflowY: "auto",
              ...customScrollStyles, // Applying the custom styles here
              overflowX: "hidden",
              // Voeg eventueel margin toe aan de rechterkant om ruimte te creëren
              marginRight: isMobile ? "0" : "20px",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h3" textAlign="left">
                Filteren
              </Typography>
              {isMobile && (
                <IconButton onClick={toggleFilters} color="primary">
                  <CloseIcon />
                </IconButton>
              )}
            </Box>

            <FormGroup sx={{ alignItems: "flex-start" }}>
              {/* Zoekveld */}
              <TextField
                label="Zoek op naam"
                variant="outlined"
                fullWidth
                value={filters.searchTerm}
                onChange={handleSearchTermChange}
                sx={{ marginBottom: "16px" }}
              />

              {/* Categorieën Filter */}
              <Box
                onClick={() => setOpenCategories(!openCategories)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Categorieën</Typography>
                {openCategories ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openCategories}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.categories.length === categories.length &&
                        categories.length > 0
                      }
                      onChange={handleCheckboxChange}
                      name="categories"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {categories.map((category) => (
                  <Tooltip title={category} placement="top" key={category}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.categories.includes(category)}
                          onChange={handleCheckboxChange}
                          name="categories"
                          value={category}
                        />
                      }
                      label={category}
                      sx={{
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    />
                  </Tooltip>
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              {/* Leveranciers Filter */}
              <Box
                onClick={() => setOpenSuppliers(!openSuppliers)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Leveranciers</Typography>
                {openSuppliers ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openSuppliers}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.suppliers.length === suppliers.length &&
                        suppliers.length > 0
                      }
                      onChange={handleCheckboxChange}
                      name="suppliers"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {suppliers.map((supplier) => (
                  <Tooltip title={supplier} placement="top" key={supplier}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.suppliers.includes(supplier)}
                          onChange={handleCheckboxChange}
                          name="suppliers"
                          value={supplier}
                        />
                      }
                      label={supplier}
                      sx={{
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    />
                  </Tooltip>
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              {/* Kaliber Filter */}
              <Box
                onClick={() => setOpenKaliber(!openKaliber)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Kaliber</Typography>
                {openKaliber ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openKaliber}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.kaliber.length === kaliberOptions.length &&
                        kaliberOptions.length > 0
                      }
                      onChange={handleCheckboxChange}
                      name="kaliber"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {kaliberOptions.map((kaliber) => (
                  <FormControlLabel
                    key={kaliber}
                    control={
                      <Checkbox
                        checked={filters.kaliber.includes(kaliber)}
                        onChange={handleCheckboxChange}
                        name="kaliber"
                        value={kaliber}
                      />
                    }
                    label={kaliber === "Niet bekend" ? "Niet bekend" : kaliber}
                    sx={{
                      marginBottom: "8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  />
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              {/* Minimale Leeftijd Filter */}
              <Box
                onClick={() => setOpenMinimalAge(!openMinimalAge)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Minimale Leeftijd</Typography>
                {openMinimalAge ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openMinimalAge}>
                {minimalAgeOptions.map((ageOption) => (
                  <FormControlLabel
                    key={ageOption}
                    control={
                      <Checkbox
                        checked={filters.minimalAge.includes(ageOption)}
                        onChange={handleCheckboxChange}
                        name="minimalAge"
                        value={ageOption}
                      />
                    }
                    label={formatMinimalAgeLabel(ageOption)}
                    sx={{
                      marginBottom: "8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  />
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              {/* Prijs Range Slider */}
              <Typography gutterBottom>Prijs Range (€)</Typography>
              <Slider
                value={filters.priceRange}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                max={500}
                step={5}
                sx={{ width: isMobile ? "100%" : "90%" }}
              />

              <Divider sx={{ my: 2 }} />

              {/* Op Voorraad Filter */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.inStock}
                    onChange={toggleInStock}
                    name="inStock"
                  />
                }
                label="Op voorraad"
                sx={{ marginBottom: "8px", width: "100%" }}
              />

              {/* Bestsellers Filter */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.bestsellers}
                    onChange={toggleHighlight}
                    name="bestsellers"
                  />
                }
                label="Bestsellers"
                sx={{ marginBottom: "8px", width: "100%" }}
              />

              {/* Toepassen Filter Knop voor Mobiel */}
              {isMobile && filtersVisible && (
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0",
                    left: 0,
                    right: 0,
                    zIndex: 9,
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      applyFilters();
                      setFiltersVisible(false);
                    }}
                    color="secondary"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CheckIcon />
                    <Typography variant="button" sx={{ ml: 1 }}>
                      Toepassen
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </FormGroup>
          </Box>
        )}

        {/* Producten Lijst */}
        <Box
          width={isMobile ? "100%" : "80%"} // Pas de breedte aan om ruimte te geven voor het filterpaneel
          ml={isMobile ? 0 : 2}
          height={isMobile ? "auto" : "70vh"} // Stel height in op 70vh voor niet-mobiele apparaten
          sx={{
            overflowY: "auto",
            ...customScrollStyles, // Applying the custom styles here
            gridTemplateColumns: isMobile
              ? "repeat(2, 1fr)"
              : "repeat(auto-fill, 300px)",
          }}
        >
          <Box
            margin="0 auto"
            display="grid"
            gridTemplateColumns={
              isMobile ? "repeat(2, 1fr)" : "repeat(auto-fill, 300px)"
            }
            justifyContent="space-around"
            rowGap="20px"
            columnGap="1.33%"
          >
            {applyFiltersAndSort.map((item) => (
              <Item item={item} key={`${item.attributes.name}-${item.id}`} />
            ))}
          </Box>

          {/* Paginatie */}
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShoppingList;
